@import '_mixins';
.banner {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #ffffff;
  border-radius: 12px;
  padding: 40px;
  max-width: 1000px;
  margin: 20px auto;
  text-align: center;
  font-family: 'Roboto', sans-serif;
  @include media-breakpoint-down('md') {
    margin: 20px auto 0px;
    padding-bottom: 0px;
  }
  
}

.school-logo img {
  width: 70%;
  height: auto;
  margin-top:20px;
}

.main-heading {
  font-size: 41px;
  font-weight: 700;
  color: #2c3e50;
  margin-bottom: 10px;
  font-family: "Poppins", sans-serif;;
}

.description {
  width: 75%;
  font-size: 20px;
  color: #7f8c8d;
  margin-bottom: 40px;
  line-height: 1.25;
  @include media-breakpoint-down('md') {
    width: 90%;
    font-size: 15px;
  }
}

.fundraising-image img {
  width: 90%;
 
  border-radius: 12px;
  margin-bottom: 15px;
}
.video-container {
  position: relative;
  padding-bottom: 45.25%; /* 16:9 aspect ratio */
  padding-left: 56.25%; /* 16:9 aspect ratio */
  height: 0;
  overflow: hidden;
  max-width: 100%;
  background: #000;
  margin: 20px 0;
  .lty-playbtn {
    display: block;
    /* Make the button element cover the whole area for a large hover/click target… */
    width: 100%;
    height: 100%;
    /* …but visually it's still the same size */
    background: no-repeat center/68px 48px;
    /* YT's actual play button svg */
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 68 48"><path d="M66.52 7.74c-.78-2.93-2.49-5.41-5.42-6.19C55.79.13 34 0 34 0S12.21.13 6.9 1.55c-2.93.78-4.63 3.26-5.42 6.19C.06 13.05 0 24 0 24s.06 10.95 1.48 16.26c.78 2.93 2.49 5.41 5.42 6.19C12.21 47.87 34 48 34 48s21.79-.13 27.1-1.55c2.93-.78 4.64-3.26 5.42-6.19C67.94 34.95 68 24 68 24s-.06-10.95-1.48-16.26z" fill="red"/><path d="M45 24 27 14v20" fill="white"/></svg>');
    position: absolute;
    cursor: pointer;
    z-index: 1;
    filter: grayscale(100%);
    transition: filter .1s cubic-bezier(0, 0, 0.2, 1);
    border: 0;
}
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    
  }
}

.countdown-timer {
  font-size: 1.5em;
  font-weight: bold;
  color:black;
  margin-bottom: 30px;
}

.progress-bar {
  width: 100%;
  max-width: 600px;
  height: 20px;
  background-color: #ecf0f1;
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 20px;
  position: relative;
}

.progress {
  width: 0%; /* Update this value dynamically based on the raised amount */
  height: 100%;
  background-color: #27ae60;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 10px 0 0 10px;
  transition: width 0.5s ease-in-out;
}

.raised-amount {
  font-size: 1.2em;
  font-weight: 600;
}

