.upload-button {
    font-size: 16px;
    padding: 8px 16px;
  }
  .ReactCrop__child-wrapper {
    overflow: visible;
  }
  :where(.css-dev-only-do-not-override-19f5tbr).ant-avatar-lg{
    width:30px;
    height:auto;
  }
  /* Mobile view adjustments */
  @media (max-width: 480px) {
    .upload-button {
      font-size: 14px;
      padding: 6px 12px;
    }
  }
  