/* EditCampaign.scss */
.edit-campaign-container {
  margin-top: 60px;
  padding: 16px 0px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  .ant-typography{
    border-bottom: 1px solid #E9EAF0;
    margin-bottom: 0px;
    padding-bottom: 16px;
    padding-left: 20px;
    padding-right: 20px;
    margin-block-start: 0px;
    .editProduct{
      margin-top: 0px;
      font-weight: "medium";
    }
  }
  }
  
  .edit-campaign-form {
    display: flex;
  padding: 10px 40px;
  flex-direction: column;
  :where(.css-dev-only-do-not-override-3mkzbg).ant-input-group-wrapper-outlined .ant-input-group-addon{
    background: none;
  }
  :where(.css-dev-only-do-not-override-3mkzbg).ant-qrcode{
    border: none;
  }
  .currencyInput{
    border: 1px solid #d9d9d9;
    padding: 16px;
    font-size: 18px;
    // height: 62px;
  }
  .flexRow{
    .ant-form-item{
      width: 47%;
    }
  }
  }
  
  .edit-campaign-form .ant-form-item {
    margin-bottom: 16px;
  }
  
  .edit-campaign-form .ant-input,
  .edit-campaign-form .ant-picker,
//   .edit-campaign-form .ant-switch {
//     width: 100%;
//   }
  
  .edit-campaign-form .ant-input-number {
    width: 100%;
  }
  
//   .edit-campaign-form .ant-btn-primary {
//     width: 100%;
//   }
  
  .edit-campaign-form img {
    display: block;
    margin-bottom: 8px;
  }
  
  .edit-campaign-form .ant-drawer {
    .ant-drawer-title {
      text-align: center;
    }
  }
  
  /* Common styles for error messages */
  .ant-form-item .ant-form-item-explain-error {
    color: #ff4d4f;
  }
  
  .ant-drawer-title {
    text-align: center;
  }
  
  .ant-radio-wrapper {
    display: flex;
    align-items: center;
  }
  